import React, { useState } from "react";
import TextInput from "../../layout/TextInput";

export default function PairingForm({
  idToCall,
  setIdToCall,
  sendPairingRequest,
}) {
  const [pairingRequestSent, setPairingRequestSent] = useState(false);
  const [pinError, setPinError] = useState(false);

  const initPairingRequest = async () => {
    if (!pairingRequestSent && idToCall !== "") {
      setPairingRequestSent(true);
      const paringRequest = await sendPairingRequest();
      if (paringRequest && !paringRequest.result) {
        setPairingRequestSent(false);
      }
    }
  };

  const inputHandler = (event) => {
    if (!event.target.value) {
      setPinError(true);
    } else {
      setPinError(false);
    }
    setIdToCall(event.target.value.trim());
  };

  return (
    <div>
      <TextInput
        errorText="Please enter a pairing pin"
        isError={pinError}
        value={idToCall}
        placeholder="Pairing Pin"
        onChange={inputHandler}
      />
      <br />
      <a href="#" className="buttonPrimary bouncy" onClick={initPairingRequest}>
        Pair
      </a>
    </div>
  );
}
