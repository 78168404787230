import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import ViewSession from "./components/view-session/ViewSession";
import Home from "./components/home/home";
import "./App.css";
import Navigation from "./layout/Navigation";
import Banner from "./layout/Banner";
import { StreamProvider } from "./context/StreamContext";
import { ApiResponseProvider } from "./context/ApiResponseContext";
import ErrorBoundary from "./layout/ErrorBoundary";
import RenderingUnit from "./components/view-session/3dViews/RenderingUnit";
import Landing from "./components/home/Landing";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import { UserProvider } from "./context/UserContext";
import Header from "./layout/Header";
import Subscription from "./components/subscription/Subscription";
import ProtectedRoute from "./utils/ProtectedRoute";
import SubscriptionSuccess from "./components/subscription/SubscriptionSuccess";
import isMobileDevice from "./utils/isMobileDevice";

import {
  RENDERER_UNIT_PAGE,
  HOME_PAGE,
  LANDING_PAGE,
  LOGIN_PAGE,
  REGISTER_PAGE,
  SUBSCRIPTION_PAGE,
  SUBSCRIPTION_SUCCESS_PAGE,
  VIEWER_LANDING_PAGE,
} from "./Constants";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path={LANDING_PAGE}
        element={<Root />}
        errorElement={<ErrorBoundary />}
      >
        <Route index element={<Landing />} />
        <Route
          exact
          path={HOME_PAGE}
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        ></Route>

        <Route exact path={REGISTER_PAGE} element={<Register />} />
        <Route exact path={LOGIN_PAGE} element={<Login />} />
        <Route exact path={VIEWER_LANDING_PAGE} element={<ViewSession />} />
        <Route
          exact
          path={SUBSCRIPTION_PAGE}
          element={
            <ProtectedRoute>
              <Subscription />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path={SUBSCRIPTION_SUCCESS_PAGE}
          element={
            <ProtectedRoute>
              <SubscriptionSuccess />
            </ProtectedRoute>
          }
        ></Route>
        {/* <Route exact path="/view/default" element={<Default />} /> */}
        <Route exact path={RENDERER_UNIT_PAGE} element={<RenderingUnit />} />
      </Route>
    )
  );

  return (
    <div className="App">
      <ApiResponseProvider>
        <UserProvider>
          <StreamProvider>
            <RouterProvider router={router} />
          </StreamProvider>
        </UserProvider>
      </ApiResponseProvider>
    </div>
  );
}

const Root = () => {
  const isViewerPath = window.location.pathname.includes(VIEWER_LANDING_PAGE);
  return (
    <>
      {!isMobileDevice({ defaultVault: true }) && !isViewerPath && (
        <Navigation />
      )}
      {!isViewerPath && <Banner />}
      {!isMobileDevice({ defaultVault: true }) && !isViewerPath && <Header />}
      <Outlet />
    </>
  );
};

export default App;
