import React from "react";
import { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import Peer from "simple-peer";
import "./../../App.css";
import PairingForm from "./PairingForm";
import { createRTCClient, getMyVideoStreamTrack } from "../RtcClientSdk";
import { useApiResponse } from "../../context/ApiResponseContext";
import Alert from "../../layout/Alert";
import Loading from "../../layout/Loading";
import isMobileDevice from "../../utils/isMobileDevice";
import {
  AUTH_CHECKING_MESSAGE,
  SUBSCRIPTION_PAGE,
  VIEWER_LANDING_PAGE,
} from "../../Constants";

function Home({ isAuthenticated, isSubscriptionActive }) {
  const [me, setMe] = useState("");
  const [stream, setStream] = useState();
  const [callAccepted, setCallAccepted] = useState(false);
  const [idToCall, setIdToCall] = useState("");
  const clientSocketRef = useRef();
  const connectionRef = useRef("");
  const [apiResponse, setApiResponse] = useApiResponse();

  useEffect(() => {
    if (!isMobileDevice({ defaultValue: false })) {
      clientSocketRef.current = io.connect(
        process.env.REACT_APP_BE_HOST + ":" + process.env.REACT_APP_BE_PORT
      );

      clientSocketRef.current.on("me", (id) => {
        setMe(id);
      });

      clientSocketRef.current.on("P2PErrorDetails", (errorDetails) => {
        alert(`P2P connection failure. \n ${errorDetails.code}`);
        console.log("P2P error details:", errorDetails);
        setCallAccepted(false);
        setStream(null);
      });

      clientSocketRef.current.on("connect_error", () => {
        alert(
          "Unable to establish connection to server.\nContact admin if error persists."
        );
      });
    } else {
      window.location.href = VIEWER_LANDING_PAGE;
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isSubscriptionActive) {
      window.location.href = SUBSCRIPTION_PAGE;
    }
  }, [isSubscriptionActive, isAuthenticated]);

  const establishVideoStreamUsingStreamClient = async () => {
    const videoStreamTrack = await getMyVideoStreamTrack().catch((err) => {
      console.log("Agora camera access error:", err);
    });
    setStream(videoStreamTrack);
  };

  const establishVideoStream = async () => {
    const mediaStreamOptions = {
      video: {
        frameRate: { ideal: 60 },
      },
      audio: false,
    };
    await navigator.mediaDevices
      .getDisplayMedia(mediaStreamOptions)
      .then((stream) => {
        setStream(stream);
      })
      .catch((err) => {
        alert("Error retrieving stream. Please check permissions");
        console.log("Stream error details:", err);
      });
  };

  const callUser = (id) => {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream: stream,
    });

    peer.on("signal", (data) => {
      clientSocketRef.current.emit("req-session", {
        userToCall: id,
        signalData: data,
        from: me,
      });
    });

    peer.on("close", () => {
      setCallAccepted(false);
    });

    connectionRef.current = peer;

    clientSocketRef.current.on("callAccepted", (signal) => {
      setCallAccepted(true);
      connectionRef.current.signal(signal);
    });

    clientSocketRef.current.on("callDenied", () => {
      setApiResponse((existingResponses) => {
        return {
          ...existingResponses,
          streamDenied: "Pairing request has been denied by user!",
        };
      });
    });
  };
  const diconnect = () => {
    console.log(stream.active);
    setCallAccepted(false);
    setStream(undefined);
    if (connectionRef.current) {
      connectionRef.current.destroy();
    }
    window.location.reload();
  };

  const sendPairingRequest = async () => {
    if (!stream) {
      setApiResponse({ streamErr: "No video stream found!" });
      return { result: false };
    }

    clientSocketRef.current.emit("stream-provider-info", {
      userToCall: idToCall,
      from: me,
      signal: { backgroundsEnabled: false },
    });

    if (process.env.REACT_APP_USE_AGORA_SDK === "true") {
      createRTCClient(idToCall, null, 321)
        .then(async (client) => {
          await client.publish([stream]);
          setCallAccepted(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      callUser(idToCall);
    }
  };

  const establishStreamForm = (
    <div style={{ display: "inline-flex" }}>
      <h1>Video Stream Not Found!</h1>
      <a
        style={{ marginLeft: 20 }}
        className="buttonGreen"
        onClick={
          process.env.REACT_APP_USE_AGORA_SDK === "true"
            ? establishVideoStreamUsingStreamClient
            : establishVideoStream
        }
      >
        Start
      </a>
    </div>
  );

  return isAuthenticated && isSubscriptionActive ? (
    <div>
      {stream ? (
        <h1>
          Stream OK <i className="fas fa-stream"></i>
        </h1>
      ) : (
        establishStreamForm
      )}
      <br />
      {apiResponse.streamErr && (
        <Alert type="error" text={apiResponse.streamErr} />
      )}
      {apiResponse.streamDenied && (
        <Alert type="error" text={apiResponse.streamDenied} />
      )}
      {!callAccepted ? (
        <PairingForm
          idToCall={idToCall}
          setIdToCall={setIdToCall}
          sendPairingRequest={sendPairingRequest}
        />
      ) : (
        <>
          <h1>
            Viewer Connected <i className="fas fa-user-check"></i>
          </h1>
          <br />
          <a className="buttonRed" onClick={diconnect}>
            Disconnect
          </a>
        </>
      )}
    </div>
  ) : (
    <Loading text={AUTH_CHECKING_MESSAGE} />
  );
}

export default Home;
