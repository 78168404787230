import React, { useEffect } from "react";
import * as subscriptionService from "../../actions/subscription.actions";
import { useSubscription } from "../../context/SubscriptionContext";
import SubscriptionItem from "./SubscriptionItem";
import { useApiResponse } from "../../context/ApiResponseContext";
import Alert from "../../layout/Alert";
import { useUser } from "../../context/UserContext";
import Loading from "../../layout/Loading";
import { MANAGE_SUBSCRIPTION_PAGE } from "../../Constants";

export default function AvailableSubscriptionPlans() {
  const [choiceOfSubscriptions, setChoiceOfSubscriptions] = useSubscription();

  const [apiResponse] = useApiResponse();
  const [user] = useUser();

  useEffect(() => {
    async function getPlans() {
      const plans = await subscriptionService.getAvailablePlans();
      if (plans.data.success) {
        setChoiceOfSubscriptions(
          ...choiceOfSubscriptions,
          plans.data.activeSubscriptionsDetails
        );
      }
    }

    if (user && !user.subscription_active) {
      getPlans();
    } else {
      window.location.href = MANAGE_SUBSCRIPTION_PAGE;
    }
  }, []);

  return (
    <div>
      {choiceOfSubscriptions && choiceOfSubscriptions.length === 0 ? (
        <Loading text={"Loading Subscription"} />
      ) : (
        <>
          <h1>Choose a Subscription</h1>
          <div>
            {apiResponse.checkoutError && (
              <Alert text={apiResponse.checkoutError} type="error" />
            )}
          </div>

          {choiceOfSubscriptions.map((subscriptions, idx) => {
            return (
              <SubscriptionItem
                key={idx}
                name={subscriptions.name}
                price={subscriptions.price}
                priceLookupKey={subscriptions.priceLookupId}
                perks={subscriptions.metadata.perks}
              />
            );
          })}
        </>
      )}
    </div>
  );
}
