import * as THREE from "three";

export function getWebGLRenderer(toneMappingEnabled) {
  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  if (toneMappingEnabled) {
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
  }

  return renderer;
}

export function getPerspectiveCamera() {
  return new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    80
  );
}

export function requireAxisHelper(axisSize) {
  return new THREE.AxesHelper(axisSize);
}

function addOrientationEventListener(handlerFunction) {
  window.addEventListener("deviceorientation", handlerFunction);
}

export async function requestDeviceOrientation({ handlerFunction }) {
  console.log("requested device orientation");

  if (
    typeof DeviceMotionEvent !== "undefined" &&
    typeof DeviceMotionEvent.requestPermission === "function"
  ) {
    // iOs 13+
    try {
      const permissionState = await DeviceMotionEvent.requestPermission();
      if (permissionState === "granted") {
        console.log("Orientation request granted");
        addOrientationEventListener(handlerFunction);
      }
    } catch (err) {
      alert("Orientation request error");
      console.log("Orientation request error", err);
    }
  } else if ("DeviceOrientationEvent" in window) {
    //not iOs 13+
    addOrientationEventListener(handlerFunction);
  } else {
    alert("Device orientation event is not supported");
  }
}

export async function sleep(ms) {
  return await new Promise((resolve) => setTimeout(resolve, ms));
}
