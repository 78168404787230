import React from "react";
import * as subscriptionService from "../../actions/subscription.actions";
import { useUser } from "../../context/UserContext";
import { useApiResponse } from "../../context/ApiResponseContext";

export default function SubscriptionItem({
  name,
  price,
  priceLookupKey,
  perks,
}) {
  const perksList = JSON.parse(perks);
  const [user] = useUser();
  const [, setApiResponse] = useApiResponse();

  const getTickIcon = () => {
    return (
      <i
        class="fa-solid fa-circle-check"
        style={{ color: "green", paddingRight: 20 }}
      ></i>
    );
  };

  const getCrossIcon = () => {
    return (
      <i
        class="fa-solid fa-circle-xmark"
        style={{ color: "red", paddingRight: 20 }}
      ></i>
    );
  };

  const handleSubscriptionChosen = async (e) => {
    const createCheckoutInfo = {
      email_address: user.email_address,
      price_lookup_key: priceLookupKey,
    };

    const response = await subscriptionService.createCheckout(
      createCheckoutInfo
    );

    if (response.data.success) {
      window.location.href = response.data.url;
    }

    if (!response.data.success) {
      setApiResponse({ checkoutError: response.data.message });
    }
  };

  return (
    <div onClick={handleSubscriptionChosen} className="subscription-item">
      <div>
        <div>
          <h1>{name}</h1>
          <h2>
            <i>£{price.toFixed(2)}/month</i>
          </h2>
        </div>

        <div>
          {perksList.map((perk, key) => {
            return (
              <h3 key={key}>
                {perk.included ? getTickIcon() : getCrossIcon()}
                {perk.name}
              </h3>
            );
          })}
        </div>
      </div>
    </div>
  );
}
