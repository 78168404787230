import * as jwt_decode from "jwt-decode";
import { LANDING_PAGE, SESSION_TOKEN_STORAGE_NAME } from "../Constants";
import ServerConnector from "../utils/ServerConnector";

export const registerUser = async (data) => {
  try {
    const response = await ServerConnector.post("/auth/register", data);
    return response;
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const loginUser = async (data) => {
  try {
    let response = await ServerConnector.post("/auth/login", data);

    if (response.data.success) {
      const { authToken } = response.data;

      localStorage.setItem(SESSION_TOKEN_STORAGE_NAME, authToken);

      const decodedToken = jwt_decode.jwtDecode(authToken);
      response.decodedToken = decodedToken;
      return response;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const verifyUser = async () => {
  try {
    const response = await ServerConnector.post("/auth/verify");
    return response;
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const reloadAuthToken = async () => {
  try {
    const response = await ServerConnector.post("/auth/reload-token");
    return response;
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const logoutUser = () => {
  localStorage.removeItem(SESSION_TOKEN_STORAGE_NAME);
  window.location.href = LANDING_PAGE;
};
