import React, { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import Peer from "simple-peer";
import "./view.css";
import { useStream } from "../../context/StreamContext";
import { useNavigate } from "react-router-dom";
import ViewSelectionButton from "./styling/ViewSelectionButton";
import Loading from "../../layout/Loading";
import { createRTCClient } from "../RtcClientSdk";
import isMobileDevice from "../../utils/isMobileDevice";
import {
  MOB_DEVICE_SIGNAL_STORAGE_NAME,
  RENDERER_UNIT_PAGE,
} from "../../Constants";

function ViewSession() {
  const [stream, setStream] = useStream();
  const [me, setMe] = useState("");
  const [connectionInfo, setConnectionInfo] = useState({
    caller: "",
    callerSignal: null,
  });
  const [callAccepted, setCallAccepted] = useState(false);
  const [sessionInfoUI, setSessionInfoUI] = useState();
  const connectionRef = useRef({});
  const clientSocketRef = useRef();
  const navigate = useNavigate();

  const MOB_STORE_INFO = JSON.parse(
    localStorage.getItem(MOB_DEVICE_SIGNAL_STORAGE_NAME)
  );

  useEffect(() => {
    async function initRtcClient() {
      const client = await createRTCClient(me, null, 123);
      client.on("user-published", (userStream, mediaType) => {
        handleUserPublished(userStream, mediaType, client);
      });
    }
    if (process.env.REACT_APP_USE_AGORA_SDK === "true" && me !== "") {
      initRtcClient();
    }

    async function handleUserPublished(userStreamInfo, mediaType, client) {
      await client.subscribe(userStreamInfo, mediaType);
      console.log("Stream aquired:", userStreamInfo);
      setStream(userStreamInfo);
    }
  }, [me, setStream]);

  useEffect(() => {
    const isMobileDeviceUser = isMobileDevice({ defaultValue: true });
    if (!isMobileDeviceUser) {
      window.location.href = "/";
    }

    if (isMobileDeviceUser && !stream) {
      clientSocketRef.current = io.connect(
        process.env.REACT_APP_BE_HOST + ":" + process.env.REACT_APP_BE_PORT
      );

      clientSocketRef.current.on("stream-provider-info", (data) => {
        localStorage.setItem(
          MOB_DEVICE_SIGNAL_STORAGE_NAME,
          JSON.stringify(data)
        );
      });

      clientSocketRef.current.on("connect_error", () => {
        //TODO: Go to some error page
      });

      clientSocketRef.current.on("error", (err) => {
        console.log("Server socket error:", err);
      });

      clientSocketRef.current.on("me", (id) => {
        setMe(id);
        window.scrollTo(0, 0);
      });

      if (process.env.REACT_APP_USE_AGORA_SDK !== "true") {
        clientSocketRef.current.on("req-session", (data) => {
          console.log(data.signal);
          setConnectionInfo((state) => {
            return { ...state, caller: data.from, callerSignal: data.signal };
          });
        });
      }
    }
    return () => {
      if (clientSocketRef.current) {
        clientSocketRef.current.disconnect();
      }
    };
  }, [stream]);

  useEffect(() => {
    function acceptCall() {
      setCallAccepted(true);
      setSessionInfoUI(null);
      if (process.env.REACT_APP_USE_AGORA_SDK !== "true") {
        const peer = new Peer({
          initiator: false,
          trickle: false,
        });

        peer.on("signal", (data) => {
          console.log(data);
          clientSocketRef.current.emit("acceptCall", {
            signal: data,
            to: connectionInfo.caller,
          });
        });

        peer.on("stream", (stream) => {
          setSessionInfoUI(<Loading text={"Loading..."} />);
          setTimeout(() => {
            setStream(stream);
            setSessionInfoUI(null);
          }, 5000);
        });

        peer.on("error", (err) => {
          console.log("P2P connection error details:", err);
          clientSocketRef.current.emit("P2PConnectionError", {
            error: err,
            to: connectionInfo.caller,
          });
        });

        if (connectionInfo.callerSignal !== undefined) {
          peer.signal(connectionInfo.callerSignal);
        }

        connectionRef.current = peer;
      }
    }

    function denyCall() {
      clientSocketRef.current.emit("denyCall", {
        to: connectionInfo.caller,
      });
      setConnectionInfo((state) => ({ ...state, callerSignal: undefined }));
    }

    var connectionTimeout;
    if (connectionInfo.callerSignal && !callAccepted) {
      connectionTimeout = setTimeout(() => {
        setSessionInfoUI(<Loading text={"Establishing stream"} />);
        setTimeout(() => {
          setSessionInfoUI(<Loading text={"Loading UI"} />);
          setTimeout(() => {
            setSessionInfoUI(<Loading text={"Obtaning stream"} />);
            setTimeout(() => {
              setSessionInfoUI(
                <>
                  <h4>Session requeset recieved. Would you like to accept? </h4>
                  <br />
                  <a className="buttonPrimary" onClick={acceptCall}>
                    Accept
                  </a>
                  <a className="buttonRed" onClick={denyCall}>
                    Deny
                  </a>
                </>
              );
            }, 15000);
          }, 1000);
        }, 2000);
      }, 1000);
    }

    return () => {
      clearTimeout(connectionTimeout);
    };
  }, [connectionInfo, callAccepted, setStream]);

  const handleNavigationToRenderer = (background) => {
    navigate(RENDERER_UNIT_PAGE, {
      state: { redirect: true, background: background },
    });
  };

  const getPairingPin = () => {
    window.scrollTo(0, 0);
    if (!!me) {
      return me;
    }
    return <Loading />;
  };

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div className="container">
      {!stream ? (
        <div className="container">
          <h1 className="font-weight-normal ">Your Pairing Pin</h1>
          <h1 className="extra-large-font">
            {" "}
            {getPairingPin()}{" "}
            <span>
              <i
                onClick={reloadPage}
                class="fa-large fa-pointer fa-solid fa-arrow-rotate-right"
              ></i>
            </span>
          </h1>
        </div>
      ) : (
        <h1>Please select viewer</h1>
      )}
      <br />
      <div>
        {sessionInfoUI && <div className="paddingtop20">{sessionInfoUI}</div>}
        {!!stream && MOB_STORE_INFO && (
          <>
            <ViewSelectionButton
              enabled={true}
              Title="Plain"
              selectionHandler={handleNavigationToRenderer}
              backgroundSource="/none"
              imageSource="/plain.jpg"
            />

            {!MOB_STORE_INFO.backgroundsEnabled && (
              <h2 className="font-weight-normal">
                ~~~~~ Locked{" "}
                <span>
                  <i class="fa-left-padding fa-solid fa-lock"></i>
                </span>{" "}
                ~~~~~
              </h2>
            )}
            <ViewSelectionButton
              enabled={MOB_STORE_INFO.backgroundsEnabled}
              Title="Cinema"
              selectionHandler={handleNavigationToRenderer}
              backgroundSource="/cinema_hall_4k.hdr"
              imageSource="/cinema.jpg"
            />
            <ViewSelectionButton
              enabled={MOB_STORE_INFO.backgroundsEnabled}
              Title="Dusk"
              selectionHandler={handleNavigationToRenderer}
              backgroundSource="/dusk_4k.hdr"
              imageSource="/dusk.jpg"
            />
            <ViewSelectionButton
              enabled={MOB_STORE_INFO.backgroundsEnabled}
              Title="Golden Hour"
              selectionHandler={handleNavigationToRenderer}
              backgroundSource="/golden_hour_4k.hdr"
              imageSource="/goldenhour.jpg"
            />
            <ViewSelectionButton
              enabled={MOB_STORE_INFO.backgroundsEnabled}
              Title="Milkyway"
              selectionHandler={handleNavigationToRenderer}
              backgroundSource="/milkyway_night_4k.hdr"
              imageSource="/milkyway.jpg"
            />
            {!MOB_STORE_INFO.backgroundsEnabled && (
              <>
                <h2 className="paddingtop20 font-weight-normal">
                  ~~~~~~~~~~~~~~~~~~~
                </h2>
                <h2 className="paddingtop20 font-weight-normal">
                  Upgrade To Unlock
                </h2>
                <h2 className="paddingtop20 font-weight-normal">
                  ~~~~~~~~~~~~~~~~~~~
                </h2>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ViewSession;
