import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  AUTH_CHECKING_MESSAGE,
  HOME_PAGE,
  SERVER_DOWN,
  SESSION_TOKEN_STORAGE_NAME,
  SUBSCRIPTION_PAGE,
} from "../../Constants";
import * as authService from "../../actions/auth.actions.js";
import Loading from "../../layout/Loading";

export default function SubscriptionSuccess({
  isAuthenticated,
  isSubscriptionActive,
}) {
  const [searchParams] = useSearchParams();

  const redirectToHome = () => {
    window.location.href = HOME_PAGE;
  };

  useEffect(() => {
    async function reloadToken() {
      const response = await authService.reloadAuthToken();

      if (!response) {
        console.log(SERVER_DOWN);
        return;
      }

      if (response.data.success) {
        localStorage.removeItem(SESSION_TOKEN_STORAGE_NAME);

        const { authToken } = response.data;
        localStorage.setItem(SESSION_TOKEN_STORAGE_NAME, authToken);
      }
    }
    reloadToken();
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isSubscriptionActive) {
      window.location.href = SUBSCRIPTION_PAGE;
    }
  }, [isAuthenticated, isSubscriptionActive]);

  return isAuthenticated && isSubscriptionActive ? (
    <div style={{ display: "inline-block" }}>
      <h1 className="extra-large-font" style={{ color: "green" }}>
        Thank you for subscribing!
      </h1>
      <div>
        <h2 className="font-weight-normal">
          <i>Checkout id: {searchParams.get("session_id")}</i>
        </h2>
        <br />

        <h2 className="font-weight-normal">
          You are all set! Please use the links below to redirect
        </h2>
        <br />

        <a onClick={redirectToHome} className="buttonGreen">
          BEGIN
        </a>
        <a onClick={redirectToHome} className="buttonPrimary">
          HOW TO USE
        </a>
      </div>
    </div>
  ) : (
    <Loading text={AUTH_CHECKING_MESSAGE} />
  );
}
