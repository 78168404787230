import { useState, useContext, createContext, useEffect } from "react";
import * as jwt_decode from "jwt-decode";
import { logoutUser } from "../actions/auth.actions";
import { LOGIN_PAGE, SESSION_TOKEN_STORAGE_NAME } from "../Constants";

const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const user = useState(undefined);

  useEffect(() => {
    const authToken = localStorage.getItem(SESSION_TOKEN_STORAGE_NAME);
    if (authToken) {
      const decodedToken = jwt_decode.jwtDecode(authToken);

      if (Date.now() / 1000 > decodedToken.exp) {
        logoutUser();
        user[1](undefined);
        window.location.href = LOGIN_PAGE;
      } else {
        user[1](decodedToken);
      }
    }
  }, []);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
