import axios from "axios";
import { SESSION_TOKEN_STORAGE_NAME } from "../Constants";

const ServerConnector = axios.create({
  baseURL: process.env.REACT_APP_BE_HOST + ":" + process.env.REACT_APP_BE_PORT,
  headers: {
    x_auth_token: localStorage.getItem(SESSION_TOKEN_STORAGE_NAME),
  },
});

export default ServerConnector;
