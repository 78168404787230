import React, { useState } from "react";

const Collapsible = ({ children, title }) => {
  const [isEpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="left-align-text">
      <div onClick={handleExpand} className="collapsable-title">
        <h2>{title}</h2>
      </div>

      <div>{isEpanded && <div className="paddingtop20">{children}</div>}</div>
    </div>
  );
};

export default Collapsible;
