import React, { useEffect, useState } from "react";
import isInputEmpty from "../../utils/isInputEmpty";
import TextInput from "../../layout/TextInput";
import { REGISTER_PAGE, VIEWER_LANDING_PAGE } from "../../Constants";
import isMobileDevice from "../../utils/isMobileDevice";
import Loading from "../../layout/Loading";
import Collapsible from "../../layout/Collapsable";

function Landing() {
  const [values, setValues] = React.useState({
    emailAddress: "",
    emailAddressErr: false,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isMobileDevice({ defaultValue: false })) {
      window.location.href = VIEWER_LANDING_PAGE;
    } else {
      setIsLoading(false);
    }
    document.addEventListener("scroll", scrollhandler);

    return () => {
      document.removeEventListener("scroll", scrollhandler);
    };
  }, []);

  const scrollhandler = (e) => {
    const head = document.getElementById("head");
    const headImg1 = document.getElementById("headImg1");
    const headImg2 = document.getElementById("headImg2");
    const headImg3 = document.getElementById("headImg3");

    headImg1.style.opacity = 0.2;
    headImg2.style.opacity = 0.2;
    headImg3.style.opacity = 0.2;
    const yLocation = window.scrollY;

    if (yLocation < 900) {
      if (yLocation > 100 && yLocation < 300) {
        headImg1.style.opacity = 1;
      }
      if (yLocation > 300 && yLocation < 600) {
        headImg2.style.opacity = 1;
      }
      if (yLocation > 600 && yLocation < 900) {
        headImg3.style.opacity = 1;
      }

      head.style.marginTop = window.scrollY + "px";
    }
  };

  const inputHandler = (event) => {
    setValues({ ...values, [event.target.id]: event.target.value });
  };

  const handleSignup = () => {
    const isEmailAddressEmpty = isInputEmpty(values.emailAddress.trim());

    setValues({
      ...values,
      emailAddressErr: isEmailAddressEmpty,
    });
    if (values.emailAddress) {
      window.location.href = `${REGISTER_PAGE}?email=${values.emailAddress}`;
    }
  };
  return !isLoading ? (
    <div>
      <div style={{ paddingTop: 100 }}>
        <h1 className="extra-large-font">
          Experience everything you enjoy in 3D
        </h1>
        <h1 className="font-weight-normal ">
          Stream movies, games and more...
        </h1>
        <TextInput
          id="emailAddress"
          value={values.emailAddress}
          placeholder="E-mail address"
          className="large-input"
          isError={values.emailAddressErr}
          onChange={inputHandler}
        />

        <a onClick={handleSignup} className="buttonGreen bouncy">
          GET STARTED
          <span>
            <i class="fa-left-padding fa-solid fa-arrow-right"></i>
          </span>
        </a>
      </div>
      <div id="head">
        <img src="headset-nobg.png" />
      </div>
      <div id="head-view">
        <img id="headImg1" src="MobileViewerImage1.PNG" />
        <br />
        <img id="headImg2" src="MobileViewerImage2.PNG" />
        <br />
        <img id="headImg3" src="MobileViewerImage3.PNG" />
      </div>

      <div className="lr200padding paddingtop20">
        <h1 className="left-align-text paddingtop20">
          Freuently Asked Questions
        </h1>
        <Collapsible title="What is Head3D?">
          <h4 className="font-weight-normal">
            Head3D is a service offering 3D immersive viewing eperience with low
            latency and high-quality visuals.
          </h4>
          <br />
          <h4 className="font-weight-normal">
            This technology enables real-time streaming of PC-based content,
            including movies, applications and media directly to a mobile device
            acting as the processing unit for VR headset.
          </h4>
        </Collapsible>
        <Collapsible title="Is VR headset provided?">
          <h4 className="font-weight-normal">
            Yes. A mobile compatible VR headset will be shipped to you.
          </h4>
        </Collapsible>
        <Collapsible title="How do I subscribe?">
          <h4 className="font-weight-normal">1. Sign-up for an account.</h4>
          <br />
          <h4 className="font-weight-normal">2. Choose a subscription.</h4>
        </Collapsible>
        <Collapsible title="What is the cancellation policy?">
          <h4 className="font-weight-normal">
            You may cancel any time. No contracts attached and there are no
            cancellation fees.
          </h4>
        </Collapsible>
      </div>
    </div>
  ) : (
    <Loading text={"Loading"} />
  );
}

export default Landing;
