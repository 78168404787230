import AgoraRTC from "agora-rtc-sdk-ng";

export async function createRTCClient(roomId, token, uid) {
  const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
  await client.join(process.env.REACT_APP_AGORA_APP_ID, roomId, token, uid);
  return client;
}

export async function getMyVideoStreamTrack() {
  return await AgoraRTC.createScreenVideoTrack({
    systemAudio: "exclude",
    encoderConfig: { frameRate: 60, bitrateMin: 1130 },
  });
}
