import React from "react";

function TextInput({
  id,
  value,
  placeholder,
  isError = false,
  errorText,
  onChange,
  className,
  type = "text",
}) {
  return (
    <div style={{ display: "block" }}>
      <input
        value={value}
        id={id}
        type={type}
        className={isError ? className + " error-text" : className}
        placeholder={placeholder}
        onChange={onChange}
      ></input>
      {isError ? <h6 className={" error-text"}>{errorText}</h6> : null}
    </div>
  );
}

export default TextInput;
