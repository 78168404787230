import React from "react";
import { useUser } from "../context/UserContext";
import { logoutUser } from "../actions/auth.actions";
import { LOGIN_PAGE } from "../Constants";

export default function Header() {
  const [user, setUser] = useUser();

  const handleSignout = () => {
    logoutUser();
    setTimeout(() => {
      setUser(undefined);
    });
  };

  return (
    <div>
      <div className="alignLeft">
        <h1>H3AD</h1>
      </div>

      <div className="alignRight">
        {user && (
          <div>
            <h5 className="font-weight-normal">User: {user.email_address}</h5>
            <h6 className="font-weight-normal">Type: {user.user_type}</h6>
          </div>
        )}

        {user ? (
          <a onClick={handleSignout} className="buttonRed">
            Sign-out
          </a>
        ) : (
          <a href={LOGIN_PAGE} className="buttonPrimary">
            Sign-in
          </a>
        )}
      </div>
    </div>
  );
}
