import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { verifyUser } from "../actions/auth.actions";
import { LOGIN_PAGE, SERVER_DOWN } from "../Constants";

export default function ProtectedRoute({ children }) {
  const [renderComponent, setRenderComponent] = useState(children);

  const authenticateChildren = (isSubscriptionActive) => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          isAuthenticated: true,
          isSubscriptionActive: isSubscriptionActive,
        });
      }
      return child;
    });
  };

  useEffect(() => {
    const isValidUser = async () => {
      const response = await verifyUser();

      if (!response) {
        console.log(SERVER_DOWN);
        return;
      }

      if (!response.data.success) {
        setRenderComponent(
          <Navigate
            to={`${LOGIN_PAGE}?redirect_to=${window.location.pathname}`}
            replace
          />
        );
      }
      if (response.data.success) {
        setRenderComponent(
          authenticateChildren(
            response.data.decodedAuthToken.subscription_active
          )
        );
      }
    };
    isValidUser();
  }, []);

  return renderComponent;
}
