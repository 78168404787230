import { useState, useContext, createContext } from "react";

const SubscriptionContext = createContext();

export function useSubscription() {
  return useContext(SubscriptionContext);
}

export function SubscriptionProvider({ children }) {
  const subscriptions = useState([]);

  return (
    <SubscriptionContext.Provider value={subscriptions}>
      {children}
    </SubscriptionContext.Provider>
  );
}
