import ServerConnector from "../utils/ServerConnector";

export const getAvailablePlans = async () => {
  try {
    const response = await ServerConnector.get(
      "/subscriptions/all-active-plans"
    );
    return response;
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const createCheckout = async (data) => {
  try {
    const response = await ServerConnector.post(
      "/payment/create-checkout-session",
      data
    );
    return response;
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const manageSubscription = async () => {
  try {
    const response = await ServerConnector.get("/payment/get-customer-portal");
    return response;
  } catch (err) {
    console.log(err);
    return err.response;
  }
};
