import React from "react";
import { SubscriptionProvider } from "../../context/SubscriptionContext";
import AvailableSubscriptionPlans from "./AvailableSubscriptionPlans";
import Loading from "../../layout/Loading";
import { AUTH_CHECKING_MESSAGE } from "../../Constants";

export default function Subscription({ isAuthenticated }) {
  const getSubscriptions = () => {
    return (
      <SubscriptionProvider>
        <AvailableSubscriptionPlans />
      </SubscriptionProvider>
    );
  };

  return isAuthenticated ? (
    getSubscriptions()
  ) : (
    <Loading text={AUTH_CHECKING_MESSAGE} />
  );
}
