import React from "react";
import "./layout.css";

export default function Banner() {
  const ENVIRONMENT = process.env.REACT_APP_DEPLOY_ENV;
  return (
    <div className="banner">
      <h5>{ENVIRONMENT === "production" ? "" : ENVIRONMENT.toUpperCase()}</h5>
    </div>
  );
}
