import React, { useEffect, useRef } from "react";
import { useStream } from "../../../context/StreamContext";
import { useLocation } from "react-router-dom";
import View3d from "./View3d";
import { Group, MathUtils } from "three";
import { requestDeviceOrientation, sleep } from "./viewHelper";
import "../styling/styling.css";
import { VIEWER_LANDING_PAGE } from "../../../Constants";

export default function RenderingUnit() {
  const [stream] = useStream();
  const refContainer = useRef(null);
  const location = useLocation();
  const view3d = new View3d();
  const screenAndBackgroundGroup = new Group();

  useEffect(() => {
    if (location.state === null) {
      window.location.href = VIEWER_LANDING_PAGE;
    }

    function loadEnvironment() {
      view3d.init();
      view3d.renderScreen({ stream: stream });
      if (location.state.background !== "/none") {
        view3d.loadTextureUsingSphere({ imagePath: location.state.background });
      }

      refContainer.current &&
        refContainer.current.appendChild(view3d.getRenderer().domElement);
      screenAndBackgroundGroup.add(view3d.getEnvironmentSphere());
      screenAndBackgroundGroup.add(view3d.getScreen());
      view3d.getScene().add(screenAndBackgroundGroup);

      const animate = function () {
        view3d.runAnimator();
        requestAnimationFrame(animate);
      };
      animate();
    }
    loadEnvironment();

    document.addEventListener("dblclick", handleViewpoinReset);
    window.addEventListener("resize", onWindowResize);

    return () => {
      view3d.getRenderer().dispose();
      window.removeEventListener("resize", onWindowResize);
      document.removeEventListener("dblclick", handleViewpoinReset);
    };
  }, [location.state, stream]);

  const handleViewpoinReset = (e) => {
    screenAndBackgroundGroup.rotation.y = -view3d.getDefaultCamera().rotation.y;
  };

  function onWindowResize(e) {
    view3d.getDefaultCamera().aspect = window.innerWidth / window.innerHeight;
    view3d.getDefaultCamera().updateProjectionMatrix();
    view3d.getRenderer().setSize(window.innerWidth, window.innerHeight);
  }

  const handleGetOrientation = async () => {
    await requestDeviceOrientation({
      handlerFunction: handleOrientationChange,
    });
    await sleep(1500);
    document.getElementById("overlay").style.display = "none";
    handleViewpoinReset();
  };

  const handleOrientationChange = (orientationEvent) => {
    view3d.getDefaultCamera().rotation.y =
      -1 * MathUtils.degToRad(orientationEvent.alpha);
  };

  return (
    <div>
      <div id="overlay">
        <h2>Application would like to track device orientation</h2>
        <br />
        <button onClick={handleGetOrientation}>Accept</button>
      </div>

      <div id="container" ref={refContainer}></div>
    </div>
  );
}
