import React from "react";
import { useApiResponse } from "../context/ApiResponseContext";

function Alert({ type = "success", text }) {
  let alertClassName = "alert-success";
  if (type === "error") {
    alertClassName = "alert-error";
  }
  const [apiError, setApiError] = useApiResponse();

  const handleCloseAlert = () => {
    const availableErrors = Object.entries(apiError).map(([key, value]) => {
      return value !== text;
    });
    setApiError(availableErrors);
  };

  return (
    <div style={{ display: "inline-flex" }} className={alertClassName}>
      <h3>{text}</h3>
      <i onClick={handleCloseAlert} class="fa-solid fa-circle-xmark"></i>
    </div>
  );
}

export default Alert;
