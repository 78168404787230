import React from "react";
import * as subscriptionService from "../actions/subscription.actions";
import { SERVER_DOWN } from "../Constants";
import { useUser } from "../context/UserContext";

export default function Navigation() {
  const [user] = useUser();

  const homePage = () => {
    window.location.href = "/";
  };

  const manageSubscriptionPage = async () => {
    const response = await subscriptionService.manageSubscription();

    if (!response) {
      console.log(SERVER_DOWN);
      return;
    }

    if (response.data.success) {
      window.open(response.data.url);
    }
  };

  return (
    <div className="nav-wrapper">
      <div id="nav">
        <h2 className="paddingtop20 nav-link" onClick={homePage}>
          Home
        </h2>
        {user && user.subscription_active && (
          <h2
            className="paddingtop20 nav-link"
            onClick={manageSubscriptionPage}
          >
            Subscription
          </h2>
        )}

        <h2 className="paddingtop20 nav-link">Information</h2>

        <h2 className="paddingtop20 nav-link">Contact</h2>

        <h2 className="paddingtop20 nav-link">T&C</h2>
      </div>
      <div className="nav-icons">
        <h2>
          <i className="paddingtop20 fa-left-padding fa fa-home"></i>
        </h2>

        {user && user.subscription_active && (
          <h2>
            <i class="paddingtop20 fa-left-padding fa-solid fa-pen-to-square"></i>
          </h2>
        )}

        <h2>
          <i className="paddingtop20 fa-left-padding fas fa-info-circle"></i>
        </h2>

        <h2>
          <i className="paddingtop20 fa-left-padding far fa-address-book"></i>
        </h2>

        <h2>
          <i className="paddingtop20 fa-left-padding fas fa-signature"></i>
        </h2>
      </div>
    </div>
  );
}
