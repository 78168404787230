//Application routes
export const LANDING_PAGE = "/";
export const HOME_PAGE = "/home";
export const LOGIN_PAGE = "/user/login";
export const REGISTER_PAGE = "/user/register";
export const VIEWER_LANDING_PAGE = "/view";
export const RENDERER_UNIT_PAGE = "/view/renderer";
export const SUBSCRIPTION_PAGE = "/subscribe";
export const MANAGE_SUBSCRIPTION_PAGE = "/subscription/manage";
export const SUBSCRIPTION_SUCCESS_PAGE = "/subscription/success";

//Application constants
export const AUTH_CHECKING_MESSAGE = "Authenticating user";
export const SESSION_TOKEN_STORAGE_NAME = "head3d_session_token";
export const MOB_DEVICE_SIGNAL_STORAGE_NAME = "mob_stream_origin_provider_info";

//Error messages
export const SERVER_DOWN = "NO RESPONSE FROM SERVER";
