import React, { useEffect } from "react";
import * as authService from "../../actions/auth.actions.js";
import isInputEmpty from "../../utils/isInputEmpty";
import { useApiResponse } from "../../context/ApiResponseContext";
import isEmailAddress from "../../utils/isEmailAddress.js";
import TextInput from "../../layout/TextInput.js";
import Alert from "../../layout/Alert.js";
import { useUser } from "../../context/UserContext.js";
import { useSearchParams } from "react-router-dom";
import { HOME_PAGE, SERVER_DOWN } from "../../Constants.js";

function Login() {
  const [values, setValues] = React.useState({
    emailAddress: "",
    emailAddressErr: false,
    emailAddressErrText: "",

    password: "",
    passwordErr: false,
    passwordErrText: "",

    showPassword: false,
    keepMeLoggedIn: false,
  });

  const [apiResponse, setApiResponse] = useApiResponse();
  const [user] = useUser();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (user) {
      window.location.href = HOME_PAGE;
    }
  }, [user]);

  const inputHandler = (e) => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };

  const handleKeepMeLoggedIn = () => {
    setValues({ ...values, keepMeLoggedIn: !values.keepMeLoggedIn });
  };

  const handleShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const resetInputValidationErrors = () => {
    setValues({
      ...values,
      emailAddressErr: false,
      emailAddressErrText: "",
      passwordErr: false,
      passwordErrText: "",
    });
  };

  const isUserInputValid = () => {
    resetInputValidationErrors();
    const isInvalidEmail = !isEmailAddress(values.emailAddress);
    const isPasswordEmpty = isInputEmpty(values.password);

    setValues({
      ...values,
      emailAddressErr: isInvalidEmail,
      emailAddressErrText: isInvalidEmail
        ? "Email address format is incorrect"
        : "",
      passwordErr: isPasswordEmpty,
      passwordErrText: isPasswordEmpty ? "Password cannot be empty" : "",
    });

    return !isInvalidEmail && !isPasswordEmpty;
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    if (isUserInputValid()) {
      const loginUserObject = {
        email_address: values.emailAddress.trim(),
        password: values.password.trim(),
        keepMeLoggedIn: values.keepMeLoggedIn,
      };
      handleLogin(loginUserObject);
    }
  };

  const handleLogin = async (loginUserObject) => {
    const response = await authService.loginUser(loginUserObject);
    console.log(response);

    if (!response) {
      console.log(SERVER_DOWN);
      return;
    }

    setApiResponse(response.data);

    if (response.status === 200) {
      const redirectSearchParam = searchParams.get("redirect_to");
      const redirect = redirectSearchParam ?? HOME_PAGE;
      setTimeout(() => {
        window.location.href = redirect;
      }, 2000);
    }

    if (response.status === 500) {
      console.log(response);
    }
  };

  return (
    <div>
      <h1 style={{ padding: 20 }}>Login</h1>
      {apiResponse.message && <Alert text={apiResponse.message} />}
      {apiResponse.loginErr && (
        <Alert type="error" text={apiResponse.loginErr} />
      )}
      <form action="#">
        <div>
          <TextInput
            id="emailAddress"
            value={values.emailAddress}
            placeholder="E-mail address"
            isError={values.emailAddressErr}
            errorText={values.emailAddressErrText}
            onChange={inputHandler}
          />
        </div>
        <div style={{ display: "inline-flex", padding: 20 }}>
          <TextInput
            id="password"
            value={values.password}
            type={values.showPassword ? "text" : "password"}
            placeholder="Password"
            isError={values.passwordErr}
            errorText={values.passwordErrText}
            onChange={inputHandler}
          />
          <i
            onClick={handleShowPassword}
            style={{ marginTop: 30 }}
            className="fa fa-pointer"
          ></i>
        </div>
        <br />
        <div style={{ display: "inline-flex" }}>
          <h4>Keep me logged in</h4>
          <i
            onClick={handleKeepMeLoggedIn}
            id="keepMeLoggedIn"
            className={
              values.keepMeLoggedIn
                ? "fa-solid fa-large fa-toggle-on"
                : "fa-solid fa-large fa-toggle-off"
            }
          ></i>{" "}
        </div>
        <div>
          <input
            className="button"
            onClick={submitHandler}
            type="submit"
            value="Login"
          ></input>
        </div>
      </form>
    </div>
  );
}

export default Login;
